enum DataLayerEventsEnum {
  SrovNewletterFormDone = 'srov_newletter_form_done',
  SrovOrderOnlineClick = 'srov_order_online_click',
  SrovCallpageContactCardClick = 'srov_callpage_contact_card_click',
  SrovCallpageMainClick = 'srov_callpage_main_click',
  SrovCallpageDone = 'srov_callpage_done',
  SrovInquiryFormSubmit = 'srov_inquiry_form_submit',
  SrovNeedHelpClick = 'srov_need_help_click',
  SrovMoreInfoClick = 'srov_more_info_click',
  SrovInquiryFormClose = 'srov_inquiry_form_close',
  SrovEmailClick = 'srov_email_click',
  SrovPhoneClick = 'srov_phone_click',
  SrovContactIssuerSubmit = 'srov_contact_issuer_submit',
  SrovContactIssuerClick = 'srov_contact_issuer_click',
  SrovFilterClick = 'srov_filter_click',
  SrovShowIssuesClick = 'srov_show_issues_click',
  SrovIssueClick = 'srov_issue_click',
  SrovCompanyClick = 'srov_emitent_click',
  SrovSortClick = 'srov_sort_click',
  SrovOrderOnlineModalClick = 'srov_order_online_modal_click',
  SrovOrderOnlineModalClose = 'srov_order_online_modal_close',
  SrovSidesheetClick = 'srov_sidesheet_click',
  SrovSidesheetClose = 'srov_sidesheet_close',
  SrovBlogClick = 'srov_blog_click',
  SrovBlockFormDone = 'srov_blog_form_done',
  SrovAffilliateRedirectClick = 'srov_affiliate_redirect_click',
  SrovEmitentDetailClick = 'srov_issuer_detail_click',
  SrovIssuerOpen = 'srov_issuer_open',
  SrovAffilliateRedirectModalClick = 'srov_affiliate_redirect_modal_click',
  SrovAffilliateRedirectModalClose = 'srov_affiliate_redirect_modal_close',
  SrovIssueMoreInfo = 'srov_issue_more_info',
  SrovBlogShowMore = 'srov_blog_show_more',
  SrovBlogCommentClick = 'srov_blog_comment_click',
  SrovBusinessCategorySelect = 'srov_business_category_select',
  SrovIssuerFormSubmit = 'srov_issuer_form_submit',
  SrovIssuerInquiryFormSubmit = 'srov_issuer_inquiry_form_submit',
  SrovSignUpStart = 'srov_sign_up_start',
  PremiumSrovIssuerFormSubmit = 'srov_issuer_form_premium_submit',
  SrovTariffSent = 'srov_tariff_done',
  SrovTariffClick = 'srov_tariff_click',
  SrovSignUpDone = 'srov_sign_up_done',
  SrovSignUpClick = 'srov_sign_up_click',
  SrovIssuerMissingDataLearnMoreClick = 'srov_issuer_missing_data_learn_more_click',
  SrovForwardContactToIssuerSubmit = 'srov_forward_contact_to_issuer_submit',
}

export default DataLayerEventsEnum
