import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { MaybeDeepReadonly } from '~/types/helpers'
import type { Company, Issue } from '~/api/types'
import type { IssueDetails } from '~~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import type { UpdatedIssue } from '~~/components/Issue/Table/types'
import { DataLayerEventsEnum } from '~~/types'
import type { IssueBuyLocations } from '~/components/Issue/types'
import type { BlogPostDetails } from '~/composables'
import type { PricingHeader } from '~/components/Pricing/PricingHeader.type'

export enum DataLayerLocationsEnum {
  Homepage = 'homepage',
  IssuesList = 'list',
  IssueDetails = 'detail',
  IssueDetailsBox = 'detail-box',
  Blog = 'blog',
  BlogPost = 'blogPost',
  HomepageBlogBanner = 'banner',
  Header = 'header',
  Floating = 'floating',
  CompanyList = 'issuerList',
  Footer = 'footer',
  HomepageBlogBottom = 'homepage_blog_bottom',
  CompanyDetail = 'company_detail',
  AddInfoToIssueBlock = 'add_info_to_issue_block',
  PricingTable = 'pricing_table',
  TopAlert = 'top_alert',
}

export enum BuyIssueModalCloseEnum {
  Button = 'button',
  Close = 'close',
  Curtain = 'curtain',
}

export type DataLayerAvailableEntities = {
  issue: Issue
  post: Omit<BlogPostDetails, 'body'>
}

export type DataLayerEvent = {
  entity?: Partial<{ [key in keyof DataLayerAvailableEntities]: DataLayerAvailableEntities[key] }>
  location: DataLayerLocationsEnum
}

const commentLocations = {
  '/': { location: DataLayerLocationsEnum.HomepageBlogBottom, strict: true },
  '/blog': { location: DataLayerLocationsEnum.Blog, strict: true },
  '/dluhopisy/': { location: DataLayerLocationsEnum.IssueDetails, strict: false },
  '/blog/': { location: DataLayerLocationsEnum.BlogPost, strict: false },
  '/emitenti/': { location: DataLayerLocationsEnum.CompanyDetail, strict: false },
}

export function useDataLayer() {
  const { dataLayer } = useScriptGoogleTagManager()

  function findCommentLocationInPath(path: string): DataLayerLocationsEnum | null {
    for (const key in commentLocations) {
      const { location, strict } = commentLocations[key]
      if ((strict && key === path) || (!strict && path.includes(key))) {
        return location
      }
    }
    return null
  }
  function onSrovComment(path: string, data?: BlogPostDetails | Issue | Company | BlogPost) {
    const location = findCommentLocationInPath(path.split('?')[0])

    dataLayer.push({
      event: DataLayerEventsEnum.SrovBlogCommentClick,
      location,
      data,
    })
  }

  function pushIssueToDataLayer(issue: IssueDetails) {
    dataLayer.push({ issue: useCloneDeep(issue) })
  }

  function onBuyNowClickEvent(_issue: MaybeDeepReadonly<UpdatedIssue | IssueDetails | ShortIssueWithId>, location: IssueBuyLocations) {
    const issue = useCloneDeep(_issue)

    dataLayer.push({
      event: DataLayerEventsEnum.SrovOrderOnlineClick,
      issue,
      location,
    })

    // Measure ecommerce
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: issue.currency,
        value: issue.nominalValue,
        items: [
          {
            item_id: issue.isin,
            item_name: issue.name,
            item_variant: 'srovnavac',
            price: issue.nominalValue,
            quantity: 1,
          },
        ],
      },
    })
  }

  function onSrovAffiliateRedirectClick(issue: MaybeDeepReadonly<UpdatedIssue | IssueDetails | ShortIssueWithId>, location: DataLayerLocationsEnum.IssuesList | DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.Floating) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovAffilliateRedirectClick,
      issue: useCloneDeep(issue),
      location,
    })
  }

  function onCallpageMainOpenEvent(location: DataLayerLocationsEnum.BlogPost | DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.IssueDetailsBox | DataLayerLocationsEnum.Homepage | DataLayerLocationsEnum.CompanyList, issue: IssueDetails | null = null,
  ) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovCallpageMainClick,
      location,
      issue: useCloneDeep(issue),
    })
  }

  function onCallpageContactCardOpenEvent() {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovCallpageContactCardClick,
    })
  }

  function onCallpageCallCreatedEvent(callpage: any) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovCallpageDone,
      callpage,
    })
  }

  function onNewsletterFormSubmitEvent(location?: DataLayerLocationsEnum) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovNewletterFormDone,
      ...(location ? { location } : {}),
    })
  }

  function onSrovInquiryFormSubmit() {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovInquiryFormSubmit,
    })
  }

  function onNeedHelpClickEvent(location: 'hero' | 'list') {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovNeedHelpClick,
      location,
    })
  }

  type ExtendedLocationDataLayerEvent = Omit<DataLayerEvent, 'location'> & {
    location: DataLayerEvent['location'] | string
  }
  function onMoreInfoContactClickEvent({ entity, location }: ExtendedLocationDataLayerEvent) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovMoreInfoClick,
      location,
      ...(entity || {}),
    })
  }

  function onInquiryFormCloseEvent() {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovInquiryFormClose,
    })
  }

  function onEmailClickEvent({ entity, location }: DataLayerEvent) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovEmailClick,
      location,
      ...(entity || {}),
    })
  }

  function onPhoneClickEvent({ entity, location }: DataLayerEvent) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovPhoneClick,
      location,
      ...(entity || {}),
    })
  }

  function onContactIssuerClickEvent(issue: Issue, location: 'banner' | 'about') {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovContactIssuerClick,
      location,
      issue: useCloneDeep(issue),
    })
  }

  function onContactIssuerSubmitEvent(issue: Issue) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovContactIssuerSubmit,
      issue: useCloneDeep(issue),
    })
  }

  function onContactCompanySubmitEvent(company: Company) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovContactIssuerSubmit,
      company: useCloneDeep(company),
    })
  }

  function onFilterClickEvent(location: 'hero' | 'side') {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovFilterClick,
      location,
    })
  }

  function onShowMoreIssuesClick(location: DataLayerLocationsEnum.Homepage | DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.IssueDetailsBox) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovShowIssuesClick,
      location,
    })
  }

  function onIssueClick(
    _issue: Issue | UpdatedIssue | ShortIssueWithId,
    location: DataLayerLocationsEnum.Homepage | DataLayerLocationsEnum.IssueDetails,
    list: 'best' | 'more' | 'secured' | 'score' | 'rate' | string | null = null,
  ) {
    const issue = useCloneDeep(_issue)

    dataLayer.push({
      event: DataLayerEventsEnum.SrovIssueClick,
      location,
      list,
      issue,
    })

    // Measure ecommerce
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: issue.currency,
        value: issue.nominalValue,
        items: [
          {
            item_id: issue.isin,
            item_name: issue.name,
            item_variant: 'srovnavac',
            price: issue.nominalValue,
            quantity: 1,
          },
        ],
      },
    })
  }

  function onSortClick(type: 'best' | 'secured' | 'score' | 'rate') {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovSortClick,
      type,
    })
  }

  function onCompanyPageClick(location: DataLayerLocationsEnum.Header) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovCompanyClick,
      location,
    })
  }

  function onSrovOrderOnlineModalClick(issue: MaybeDeepReadonly<IssueDetails | Issue | UpdatedIssue | ShortIssueWithId>, location: DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.IssuesList | DataLayerLocationsEnum.Floating) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovOrderOnlineModalClick,
      issue: useCloneDeep(issue),
      location,
    })
  }

  function onSrovAffiliateRedirectModalClick(issue: MaybeDeepReadonly<IssueDetails | Issue | UpdatedIssue | ShortIssueWithId>, location: DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.IssuesList | DataLayerLocationsEnum.Floating) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovAffilliateRedirectModalClick,
      issue: useCloneDeep(issue),
      location,
    })
  }

  /**
   *
   * @param issue
   * @param location
   * @param type
   * 'button' - button is the custom button at the bottom of the modal
   *
   * 'close' - close is the X on the top of the modal
   *
   * 'curtain' - curtain is the click on outside backdrop of the modal
   */
  function onSrovAffiliateRedirectModalClose(issue: MaybeDeepReadonly<IssueDetails | Issue | UpdatedIssue | ShortIssueWithId>, location: IssueBuyLocations, type: BuyIssueModalCloseEnum) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovAffilliateRedirectModalClose,
      issue: useCloneDeep(issue),
      location,
      type,
    })
  }

  /**
   *
   * @param issue
   * @param location
   * @param type
   * 'button' - button is the custom button at the bottom of the modal
   *
   * 'close' - close is the X on the top of the modal
   *
   * 'curtain' - curtain is the click on outside backdrop of the modal
   */
  function onSrovOrderOnlineModalClose(issue: MaybeDeepReadonly<IssueDetails | Issue | UpdatedIssue | ShortIssueWithId>, location: IssueBuyLocations, type: BuyIssueModalCloseEnum) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovOrderOnlineModalClose,
      issue: useCloneDeep(issue),
      location,
      type,
    })
  }

  function onSrovSidesheetClick(issue: IssueDetails, type: 'collateralization' | 'about' | 'yield' | 'score' | 'documents' | 'financial_results') {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovSidesheetClick,
      issue: useCloneDeep(issue),
      type,
    })
  }

  function onSrovSidesheetClose(issue: IssueDetails, type: 'collateralization' | 'about' | 'yield' | 'score' | 'documents' | 'financial_results') {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovSidesheetClose,
      issue: useCloneDeep(issue),
      type,
    })
  }

  function onBlogClick(location: DataLayerLocationsEnum.HomepageBlogBanner | DataLayerLocationsEnum.Header, post?: { title: string, author: string }) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovBlogClick,
      location,
      post,
    })
  }

  function onSrovBlogFormDone() {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovBlockFormDone,
    })
  }

  function onCompanyDetailClick(location: DataLayerLocationsEnum.CompanyList, data: unknown) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovEmitentDetailClick,
      location,
      data,
    })
  }

  function onBlogShowMoreClick(location: DataLayerLocationsEnum.HomepageBlogBottom) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovBlogShowMore,
      location,
    })
  }

  function onSrovSignUpStart(location: DataLayerLocationsEnum, url: string) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovSignUpStart,
      location,
      path: url,
    })
  }

  function onSrovSignUpDone(location: DataLayerLocationsEnum, url: string) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovSignUpDone,
      location,
      path: url,
    })
  }

  function onSrovSignUpClick(location: DataLayerLocationsEnum, url: string) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovSignUpClick,
      location,
      path: url,
    })
  }

  function onSrovIssuerOpen() {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovIssuerOpen,
    })
  }

  function onSrovTariffSent(url: string, tariff?: string) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovTariffSent,
      location: DataLayerLocationsEnum.PricingTable,
      path: url,
      tariff: useCloneDeep(tariff),
    })
  }

  function onSrovTariffClick(url: string, tariff?: PricingHeader) {
    dataLayer.push({
      event: DataLayerEventsEnum.SrovTariffClick,
      location: DataLayerLocationsEnum.PricingTable,
      path: url,
      tariff: useCloneDeep(tariff),
    })
  }

  function trackEvent(event: DataLayerEventsEnum, payload: {
    location: DataLayerLocationsEnum
    [key: string]: unknown
  }) {
    dataLayer.push({ event, ...payload })
  }

  return {
    pushIssueToDataLayer,
    onBuyNowClickEvent,
    onCallpageMainOpenEvent,
    onCallpageContactCardOpenEvent,
    onCallpageCallCreatedEvent,
    onNewsletterFormSubmitEvent,
    onSrovInquiryFormSubmit,
    onNeedHelpClickEvent,
    onMoreInfoContactClickEvent,
    onInquiryFormCloseEvent,
    onEmailClickEvent,
    onPhoneClickEvent,
    onContactIssuerClickEvent,
    onContactIssuerSubmitEvent,
    onFilterClickEvent,
    onShowMoreIssuesClick,
    onIssueClick,
    onSortClick,
    onSrovOrderOnlineModalClick,
    onSrovOrderOnlineModalClose,
    onSrovSidesheetClick,
    onSrovSidesheetClose,
    onBlogClick,
    onSrovBlogFormDone,
    onCompanyPageClick,
    onSrovAffiliateRedirectClick,
    onCompanyDetailClick,
    onSrovAffiliateRedirectModalClick,
    onSrovAffiliateRedirectModalClose,
    onContactCompanySubmitEvent,
    onBlogShowMoreClick,
    onSrovComment,
    onSrovSignUpStart,
    onSrovSignUpDone,
    onSrovSignUpClick,
    onSrovIssuerOpen,
    onSrovTariffSent,
    onSrovTariffClick,
    trackEvent,
  }
}
